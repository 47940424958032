import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c_card_grid__card" }
const _hoisted_2 = { class: "c_card_grid_date" }
const _hoisted_3 = { class: "c_card_grid_date_start" }
const _hoisted_4 = {
  key: 0,
  class: "c_card_grid_date_end"
}
const _hoisted_5 = {
  key: 0,
  class: "c_card_grid_duration"
}
const _hoisted_6 = {
  key: 0,
  class: "c_card_grid__card c_card_grid_feedback"
}
const _hoisted_7 = { class: "c_card_grid__card c_card_grid_comment" }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c_card_grid", _ctx.event?.type])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('cgmEvent.start-date')), 1),
          _createElementVNode("p", null, [
            _cache[0] || (_cache[0] = _createElementVNode("i", {
              class: "icon-calendar",
              "aria-hidden": "true"
            }, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.startDateFormated), 1)
          ]),
          _createElementVNode("p", null, [
            _cache[1] || (_cache[1] = _createElementVNode("i", {
              class: "icon-clock",
              "aria-hidden": "true"
            }, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.startHoursFormated), 1)
          ])
        ]),
        (_ctx.event?.type == _ctx.EventType.REST)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('cgmEvent.end-date')), 1),
              _createElementVNode("p", null, [
                _cache[2] || (_cache[2] = _createElementVNode("i", {
                  class: "icon-calendar",
                  "aria-hidden": "true"
                }, null, -1)),
                _createTextVNode(_toDisplayString(_ctx.endDateFormated), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[3] || (_cache[3] = _createElementVNode("i", {
                  class: "icon-clock",
                  "aria-hidden": "true"
                }, null, -1)),
                _createTextVNode(_toDisplayString(_ctx.endHoursFormated), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.event?.type == _ctx.EventType.REST || _ctx.event?.type == _ctx.EventType.TRAINING)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("i", {
              class: "icon-timer",
              "aria-hidden": "true"
            }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.hoursDuration && _ctx.hoursDuration > 0 ? _ctx.t('common.hours', { hours: _ctx.hoursDuration }, _ctx.hoursDuration) : '') + " " + _toDisplayString(_ctx.minutesDuration && _ctx.minutesDuration > 0
            ? _ctx.t('common.minutes', { minutes: _ctx.minutesDuration }, _ctx.minutesDuration)
            : ''), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.event?.type == _ctx.EventType.REST || _ctx.event?.type == _ctx.EventType.TRAINING || _ctx.event?.type == _ctx.EventType.FEELING)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('cgmEvent.feedback')), 1),
          _createElementVNode("p", {
            class: _normalizeClass(["c-event__feelings", _ctx.event?.rating])
          }, _toDisplayString(_ctx.event.rating ? _ctx.$t('cgmEvent.eventRating.' + _ctx.event?.rating) : _ctx.$t('common.no-data')), 3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h3", null, [
        _cache[5] || (_cache[5] = _createElementVNode("i", {
          class: "icon-message",
          "aria-hidden": "true"
        }, null, -1)),
        _createTextVNode(_toDisplayString(_ctx.$t('cgmEvent.comment')), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.event?.comment ? _ctx.event.comment : _ctx.$t('common.no-data')), 1)
    ]),
    (_ctx.event?.type == _ctx.EventType.MEAL || _ctx.event?.type == _ctx.EventType.CUSTOM)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.images.length > 0 ? 'c_card_grid_files' : 'c_card_grid_no_files')
        }, [
          (_ctx.images.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.images, (image, index) => {
                return (_openBlock(), _createBlock(_component_Image, {
                  key: index,
                  src: image.url,
                  alt: "",
                  imageClass: "c_card_grid_files_image",
                  preview: ""
                }, null, 8, ["src"]))
              }), 128))
            : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('cgmEvent.no-pictures')), 1))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}