import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = { class: "p-grid--cgm-event" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_CgmAccordionScoreList = _resolveComponent("CgmAccordionScoreList")!
  const _component_CgmGlucoseChart = _resolveComponent("CgmGlucoseChart")!
  const _component_EventDetailsCard = _resolveComponent("EventDetailsCard")!

  return (!_ctx.event)
    ? (_openBlock(), _createBlock(_component_LoadingComponent, {
        key: 0,
        "spinner-color": "lavender-700"
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.event)
          ? (_openBlock(), _createBlock(_component_HeadingComponent, {
              key: 0,
              title: _ctx.eventName,
              breadcrumb: _ctx.breadcrumb
            }, {
              left: _withCtx(() => [
                _createVNode(_component_Button, {
                  class: "p-button p-button-only-icon",
                  onClick: _ctx.onGoBack
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("i", {
                      class: "icon-chevron_left",
                      "aria-hidden": "true",
                      "aria-label": ""
                    }, null, -1)
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["title", "breadcrumb"]))
          : _createCommentVNode("", true),
        _createElementVNode("main", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.eventHasMissingData && _ctx.isTooEarlyToScoreTheEvent)
                ? (_openBlock(), _createBlock(_component_EmptyState, {
                    key: 0,
                    icon: "icon-clock",
                    class: "c-empty-state",
                    heading: _ctx.$t('cgmEvent.tooEarlyToMeasureHeading'),
                    text: _ctx.$t('cgmEvent.tooEarlyToMeasureText'),
                    textAtBottom: ""
                  }, null, 8, ["heading", "text"]))
                : (_ctx.eventHasMissingData && _ctx.dataCanBeCollected)
                  ? (_openBlock(), _createBlock(_component_EmptyState, {
                      key: 1,
                      icon: "icon-alarm",
                      class: "c-empty-state",
                      heading: _ctx.$t('cgmEvent.missingDataHeading'),
                      text: _ctx.$t('cgmEvent.missingDataText'),
                      textAtBottom: true
                    }, null, 8, ["heading", "text"]))
                  : (_ctx.eventHasMissingData)
                    ? (_openBlock(), _createBlock(_component_EmptyState, {
                        key: 2,
                        icon: "icon-confused",
                        class: "c-empty-state",
                        heading: _ctx.$t('cgmEvent.noDataHeading'),
                        text: _ctx.$t('cgmEvent.noDataText'),
                        textAtBottom: ""
                      }, null, 8, ["heading", "text"]))
                    : (_openBlock(), _createBlock(_component_CgmAccordionScoreList, {
                        key: 3,
                        title: _ctx.$t('cgm-scores.data-event'),
                        statistics: _ctx.statistics
                      }, null, 8, ["title", "statistics"]))
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.glucoseSerie && _ctx.chartStartEndDates)
                ? (_openBlock(), _createBlock(_component_CgmGlucoseChart, {
                    key: 0,
                    chartId: "event-glucose-chart",
                    serie: _ctx.glucoseSerie,
                    events: [_ctx.event],
                    clickableAnnotations: false,
                    chartStartEndDates: _ctx.chartStartEndDates
                  }, null, 8, ["serie", "events", "chartStartEndDates"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('cgmEvent.event-details')), 1),
              _createVNode(_component_EventDetailsCard, {
                event: _ctx.event,
                patientId: _ctx.patientId
              }, null, 8, ["event", "patientId"])
            ])
          ])
        ])
      ], 64))
}