
import { EventDto, EventType } from '@/models/Event';
import { MedicalTest } from '@/models/MedicalTest';
import { eventService } from '@/services/EventService';
import { defineComponent, ref, onMounted, computed, Ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import HeadingComponent from '../HeadingComponent.vue';
import CgmAccordionScoreList from './CgmAccordionScoreList.vue';
import EventDetailsCard from './event/EventDetailsCard.vue';
import CgmGlucoseChart from '../charts/CgmGlucoseChart.vue';
import { Chart, ChartType } from '@/models/Statistics';
import LoadingComponent from '../LoadingComponent.vue';
import { ServiceError } from '@/services/util/ServiceError';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import EmptyState from '@/components/EmptyState.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import { ScoreType } from '@/models/Score';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { medicalTestService } from '@/services/MedicalTestService';
import { patientService } from '@/services/PatientService';
import { userService } from '@/services/UserService';
import router from '@/router';
export default defineComponent({
  components: {
    HeadingComponent,
    CgmAccordionScoreList,
    EventDetailsCard,
    CgmGlucoseChart,
    LoadingComponent,
    EmptyState,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const storeCGM = useCgmDetailStore();
    const patientId = route.params.patientId as string;
    const medicalTestId = route.params.medicalTest as string;
    const eventId = route.params.eventId as string;
    const patientTimezone = ref('');
    const medicalTest: Ref<MedicalTest | undefined> = ref();
    const event = ref<EventDto | undefined>();

    const SENSOR_NFC_DATA_STORE_HOURS = 8;

    const loading = ref(true);
    const breadcrumb = ref();
    const routeBreadcrumb = route.meta.breadcrumb as any[];
    const eventStatistics = ref();
    const statistics = computed(() => eventStatistics.value?.statistics);

    const efficiency = computed(() =>
      statistics.value && statistics.value.scores
        ? statistics.value.scores.find((item: any) => item.type == ScoreType.EFFICIENCY)
        : undefined,
    );

    const eventHasMissingData = computed(() => {
      return efficiency.value?.value == null;
    });

    const timeXaxisData = computed(() => {
      if (statistics.value) {
        return statistics.value.charts[0].series[0].xaxis;
      }
      return [];
    });

    const dataCanBeCollected = computed(() => {
      if (eventStatistics?.value?.startDateChart && eventStatistics?.value?.endDateChart) {
        const startHasData = moment(timeXaxisData.value[0]).isBefore(eventStatistics?.value?.startDateChart);
        const hoursFromStart = moment().diff(
          dateFormat.utcToUserTimezone(eventStatistics?.value?.startDateChart),
          'hours',
        );
        const endHasData = moment(timeXaxisData.value[timeXaxisData.value.length - 1]).isAfter(
          eventStatistics?.value?.endDateChart,
        );
        const hoursFromEnd = moment().diff(dateFormat.utcToUserTimezone(eventStatistics?.value?.endDateChart), 'hours');

        return (
          (!startHasData && hoursFromStart < SENSOR_NFC_DATA_STORE_HOURS) ||
          (!endHasData && hoursFromEnd < SENSOR_NFC_DATA_STORE_HOURS)
        );
      }
      return false;
    });

    const isTooEarlyToScoreTheEvent = computed(() => {
      return eventStatistics?.value?.endDateChart
        ? moment().isBefore(dateFormat.utcToUserTimezone(eventStatistics?.value?.endDateChart))
        : false;
    });

    const glucoseSerie = computed(
      () => statistics.value?.charts?.find((chart: Chart) => chart.type == ChartType.CGM_DAILY)?.series[0],
    );

    const chartStartEndDates = computed(() => {
      if (!eventStatistics.value) {
        return undefined;
      }
      return {
        startDateChart: dateFormat.utcToTimezone(eventStatistics.value.startDateChart, patientTimezone.value),
        endDateChart: dateFormat.utcToTimezone(eventStatistics.value.endDateChart, patientTimezone.value),
      };
    });
    onMounted(async () => {
      await loadMedicalTestData();
      await loadEventDetailsAndStadistics();
    });

    async function loadMedicalTestData() {
      const result = await medicalTestService.find(patientId, medicalTestId);
      if (!(result instanceof ServiceError)) {
        storeCGM.setMedicalTestSelected(result);
        breadcrumb.value = [
          { label: t(routeBreadcrumb[0].parent), to: `/patient/${patientId}/medical-tests` },
          {
            label: result.name,
            to: `/patient/${patientId}/medical-test/cgm/${medicalTestId}/follow-up`,
          },
          {
            label: t('events.event-details'),
            to: '#',
            disabled: true,
          },
        ];
        medicalTest.value = Object.assign({}, result);
      }

      const medicalTestPatient = await patientService.find(patientId);
      if (!(medicalTestPatient instanceof ServiceError)) {
        const medicalTestUser = await userService.find(medicalTestPatient?.userId);
        if (!(medicalTestUser instanceof ServiceError)) {
          patientTimezone.value = medicalTestUser.timezone;
        }
      }
      storeCGM.setMedicalTestSelectedUserTimezone(patientTimezone.value);
    }

    onBeforeRouteLeave((to) => {
      if (
        to.name !== 'medicaltest-detail-follow-up' &&
        to.name !== 'medicaltest-detail-events' &&
        to.name !== 'medicaltest-detail-patient-info' &&
        to.name !== 'medicaltest-detail-final-analysis'
      ) {
        storeCGM.resetMedicalTestSelectedAll();
      }
    });

    async function loadEventDetailsAndStadistics() {
      loading.value = true;
      const [eventData, eventStatisticsData] = await Promise.all([
        eventService.find(eventId, patientId),
        eventService.findStatistics(eventId, patientId),
      ]);

      loading.value = false;

      if (!(eventData instanceof ServiceError)) {
        event.value = eventData;
      }

      if (!(eventStatisticsData instanceof ServiceError)) {
        eventStatistics.value = eventStatisticsData;
      }
    }

    const eventName = computed(() => {
      if (event.value?.name) {
        return event.value.name;
      }
      if (event.value?.subType) {
        return t(`events.subtype.${event.value.subType}`);
      }
      return t(`events.${event.value?.type}`);
    });

    async function onGoBack() {
      if (window.history.state.back != '') {
        router.go(-1);
      } else {
        await router.push(`/patient/${patientId}/medical-test/cgm/${medicalTestId}/follow-up`);
      }
    }

    return {
      loading,
      event,
      breadcrumb,
      statistics,
      medicalTest,
      EventType,
      glucoseSerie,
      chartStartEndDates,
      patientId,
      efficiency,
      eventHasMissingData,
      dataCanBeCollected,
      isTooEarlyToScoreTheEvent,
      onGoBack,
      eventName,
    };
  },
});
