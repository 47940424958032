
import dateFormat from '@/helpers/DateFormat.helper';
import { EventDto, EventType } from '@/models/Event';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import moment from 'moment';
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { documentService } from '@/services/DocumentService';
import { ServiceError } from '@/services/util/ServiceError';
import { Document } from '@/models/Document';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  props: {
    event: {
      type: Object as PropType<EventDto | undefined>,
    },
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const avatar = window.location.origin + '/assets/layout/images/avatar.svg';
    const storeCGM = useCgmDetailStore();
    const patientTimeZone = computed(() => storeCGM.getMedicalTestSelectedUserTimezone);
    const images: Ref<any[]> = ref([]);
    const startDate = computed(() => {
      return props.event && patientTimeZone.value
        ? dateFormat.utcToTimezoneObject(props.event.date, patientTimeZone.value)
        : undefined;
    });
    const startDateFormated = computed(() => (startDate.value ? startDate.value.format('DD-MM-YYYY') : ''));
    const startHoursFormated = computed(() => (startDate.value ? startDate.value.format('HH:mm A') : ''));

    const endDate = computed(() => {
      return props.event && props.event.endDate && patientTimeZone.value
        ? dateFormat.utcToTimezoneObject(props.event.endDate, patientTimeZone.value)
        : undefined;
    });
    const endDateFormated = computed(() => (endDate.value ? endDate.value.format('DD-MM-YYYY') : ''));
    const endHoursFormated = computed(() => (endDate.value ? endDate.value.format('HH:mm A') : ''));

    const durationAsMinutes = computed(() =>
      props.event?.date && props.event?.endDate
        ? calculateDurationAsMinutes(props.event?.date, props.event?.endDate)
        : undefined,
    );
    const hoursDuration = computed(() =>
      durationAsMinutes.value ? Math.trunc(durationAsMinutes.value / 60) : undefined,
    );
    const minutesDuration = computed(() =>
      durationAsMinutes.value ? Math.trunc(durationAsMinutes.value % 60) : undefined,
    );

    function calculateDurationAsMinutes(start: any, end: any): number {
      return moment(end).diff(start, 'minutes');
    }

    async function getImages(documents: Document[] | undefined): Promise<any[]> {
      if (!documents) {
        return [];
      }
      let eventImages: any = [];
      for (const document of documents) {
        const s3Url = await documentService.getContentURLFromS3(
          props.patientId,
          document.entityType,
          document.entityId,
          document.id,
        );
        if (!(s3Url instanceof ServiceError)) {
          eventImages.push({ url: s3Url.url, id: document.id });
        }
      }
      return eventImages;
    }

    onMounted(async () => {
      images.value = await getImages(props.event?.documents);
    });

    return {
      startDateFormated,
      startHoursFormated,
      endDateFormated,
      endHoursFormated,
      hoursDuration,
      minutesDuration,
      durationAsMinutes,
      EventType,
      images,
      avatar,
      t,
    };
  },
});
